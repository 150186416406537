import React from "react";

const Banner = () => {
  return (
    <>
      <div className="banner-hero">
        <div class="container">
          <div class="row">
            <div class="col-md-6" id="banner-text">
              <h3 class="title" id="banner-heading">
                Your Local, Affordable Ride-Sharing Solution "Rides That Care"
              </h3>
              <p class="lead" id="banner-para">
                At Rydeon, we believe in delivering more than just
                transportation. We bring value to our riders, empower our
                drivers, and give back to the community. Experience safe,
                affordable, and reliable rides with a company that truly cares.
              </p>
            </div>
              <div style={{marginBottom: "30px"}}>
              <a href="#rydeon-footer" className="cta-button">Download the App</a>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
