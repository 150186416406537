import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <>
      <footer id="rydeon-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="footer-links-heading">Contact Info</p>
              <a className="contact-anchor" href="tel:+18559103276">
                (855) 910-3276
              </a>
              <a className="contact-anchor" href="mailto:support@rydeon.ca">
                support@rydeon.ca
              </a>
            </div>
            <div className="col-md-6">
              <p className="footer-links-heading">App Download</p>
                <img
                  className="app-download-image"
                  src="./assets/download/app-store.svg"
                  alt="app store image"
                />
                <img
                  className="app-download-image"
                  src="./assets/download/google-play.svg"
                  alt="google play store image"
                />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <p className="footer-links-heading">Social Links</p>
              <ul className="rydeon-footer-ul">
                <li>
                  <a href="#">
                    <FacebookIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <LinkedInIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <InstagramIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyright-footer">© 2024 Rydeon All Rights Reserved</div>
    </>
  );
};

export default Footer;
