import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SmallHeader from "./app/components/SmallHeader";
import Footer from "./app/components/Footer";
import HomePage from "./app/pages/home/HomePage";
import Page404 from "./app/pages/Page404";
import PageTitle from "./app/components/PageTitle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./app/main.css"
import "./App.css";
import "./AppMediaQuery.css";

function App() {
  return (
    <>
      <Router>
        <SmallHeader />

        <Routes>
          <Route
            path="/"
            element={
              <>
                <PageTitle title="Rydeon - Home Page" />
                <HomePage />
              </>
            }
          />
          <Route
            path="/*"
            element={
              <>
                <PageTitle title="404 - Page not found" />
                <Page404 />
              </>
            }
          />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
