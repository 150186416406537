import React from "react";

const Intro = () => {
  return (
    <>
      <div className="intro-section">
        <div className="container">
          <p className="intro-heading">Welcome to Rydeon</p>
          <p className="intro-subheading">
            Your new local rideshare solution transforming how you move around
            the city.
          </p>
          <p className="intro-text">
            Our mission is simple: to provide affordable, safe, and efficient
            rides while giving back to the community. With our app, not only do
            you get where you need to go, but you reach there safely and
            efficiently, paying a reasonable price.
          </p>
        </div>
      </div>
    </>
  );
};

export default Intro;
