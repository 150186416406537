import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WidgetsIcon from "@mui/icons-material/Widgets";
import NoCrashIcon from "@mui/icons-material/NoCrash";

const SafetyAndSecurity = () => {
  return (
    <>
      <div className="safety-security-container">
        <div className="container mt-5 mb-5 pb-5">
          <p className="safety-security-heading ">
            Safety and Security For Drivers & Riders
          </p>
          {/* <p className="safety-security-subheading">For Drivers & Riders</p> */}
          <div className="row">
            <div className="col-md-6">
              <div>
                <img
                  className="rider-and-driver-image"
                  src="https://us-ride.com/wp-content/uploads/2022/05/Understanding-Driver-Ratings-with-RIDE-min.jpg"
                  alt="rider image"
                />
              </div>
            </div>
            <div className="col-md-6">
              <ul className="rider-works-steps-ul">
                <li>
                  <b className="safetySecurity-bold">
                    <VerifiedUserIcon className="safetySecurity-icons" />
                    Verified Drivers:
                  </b>
                  <p>
                    Every driver undergoes a strict background check, driver
                    licensing, and vehicle inspection to ensure a safe,
                    professional experience.
                  </p>
                </li>
                <li>
                  <b className="safetySecurity-bold">
                    <hr />
                    <WidgetsIcon className="safetySecurity-icons" /> In-App Safety
                    Features:
                  </b>
                  <p>
                    With real-time ride tracking, in-app emergency support,
                    speed monitoring, and contactless payments, your safety is
                    our priority.
                  </p>
                </li>
                <li>
                  <b className="safetySecurity-bold">
                    <hr />
                    <NoCrashIcon className="safetySecurity-icons" /> Vehicle
                    Standards:
                  </b>
                  <p>
                    We ensure that all vehicles meet our high safety standards,
                    including regular inspections, built-in safety systems, and
                    insurance coverage.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetyAndSecurity;
