import React from "react";

const JoinRefferal = () => {
  return (
    <>
      <div className="referral-section">
        <div className="container">
          <h2 className="referral-section-heading">Join Our Referral Program</h2>
          <p className="referral-section-subheading">Earn Rewards Together</p>

          <p className="referral-description">
            Riders and drivers alike can benefit from our referral program.
            Invite friends to join the app, and both you and your referrals will
            enjoy bonuses and rewards on every ride. The more you share, the
            more you save!
          </p>
        </div>
      </div>
    </>
  );
};

export default JoinRefferal;
