import React from "react";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PeopleIcon from "@mui/icons-material/People";
import RedeemIcon from "@mui/icons-material/Redeem";

const WhyChooseRydeon = () => {
  return (
    <>
      <div className="why-choose-section" id="whyRydeonDiv">
        <div className="container">
          <h2 className="why-choose-heading">Why Choose Rydeon?</h2>
          <div className="row mt-5 mb-5">
            <div className="col-md-6 why-choose-part">
              <LocalActivityIcon className="why-choose-icons" />
              <p className="why-choose-subheading">Local and Affordable</p>
              <p>Enjoy competitive prices on every ride.</p>
            </div>
            <div className="col-md-6">
              <HealthAndSafetyIcon className="why-choose-icons" />
              <p className="why-choose-subheading">Safety First</p>
              <p>We focus on the safety of riders, drivers, and vehicles.</p>
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-md-6 ">
              <PeopleIcon className="why-choose-icons" />
              <p className="why-choose-subheading">Community Centered</p>
              <p>We share a portion of our profits with the local community.</p>
            </div>
            <div className="col-md-6">
              <RedeemIcon className="why-choose-icons" />
              <p className="why-choose-subheading">Incentives for All</p>
              <p>Both riders and drivers benefit from rewards and bonuses.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseRydeon;
