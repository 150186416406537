import React from 'react'
import Banner from './Banner'
import Intro from './Intro'
import HowItWorks from './HowItWorks'
import SafetyAndSecurity from './SafetyAndSecurity'
import JoinRefferal from './JoinRefferal'
import WhyChooseRydeon from './WhyChooseRydeon'
import "./home.css"

const HomePage = () => {
  return (
    <>
      <Banner /> 
      <Intro />
      <HowItWorks />
      <SafetyAndSecurity />
      <JoinRefferal />
      <WhyChooseRydeon />

    </>
  )
}

export default HomePage;