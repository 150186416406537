import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GridOnIcon from "@mui/icons-material/GridOn";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

const HowItWorks = () => {
  return (
    <>
      <div className="container how-works-section mt-5 mb-5" id="howItWorksSection">
        <p className="how-works-h">How It Works ?</p>
        <div className="row for-rider">
          <p className="how-works-h-rider">For Riders</p>
          <div className="col-md-6">
            <div>
              <img
                className="rider-image"
                src="https://media.istockphoto.com/id/1138563346/photo/happy-business-man-in-car-using-phone.jpg?s=612x612&w=0&k=20&c=VmoWSxgwSfPtS-0D_erCqr0ZLzSBjL-9Bos5Ah_s9XI="
                alt="rider image"
              />
            </div>
          </div>
          <div className="col-md-6">
            <ul className="rider-works-steps-ul">
              <li>
                <b className="how-works-bold">
                  <AttachMoneyIcon className="how-wirks-icons" />
                  Affordable Rides:
                </b>
                <p>
                  Get to your destination without breaking the bank. Our rides
                  are competitively priced to provide the best value.
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <AddLinkIcon className="how-wirks-icons" /> Referral Program:{" "}
                </b>
                <p>
                  Invite friends, save money. With every successful referral,
                  both you and your friend enjoy discounts on future rides.
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <PeopleIcon className="how-wirks-icons" /> Community Impact:{" "}
                </b>
                <p>
                  Every time you ride, a portion of our profits goes directly to
                  local charities, help make a difference in your city.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row for-driver mt-4">
          <p className="how-works-h-driver">For Driver</p>
          <div className="col-md-6">
            <ul className="driver-works-steps-ul">
              <li>
                <b className="how-works-bold">
                  <MonetizationOnIcon className="how-wirks-icons" /> Earn More:{" "}
                </b>
                <p>
                  Enjoy ongoing incentives and rewards through our driver &
                  rider referral programs. The more you drive and refer, the
                  more you earn.
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <GridOnIcon className="how-wirks-icons" /> Transparency:{" "}
                </b>
                <p>
                  No hidden fees or charges and exact amount paid by the rider
                  is the displayed fare.{" "}
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <HealthAndSafetyIcon className="how-wirks-icons" /> Safety
                  First:{" "}
                </b>
                <p>
                  We prioritize your safety by offering vehicle checks and 24/7
                  support to ensure peace of mind while you work.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="">
              <img
                className="driver-image"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDahk2fE6JSjSgNL8uU-CcdmdNxoz0aPgvEw&s"
                alt="driver image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
